import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {SearchDelisAllianceService} from '../services/search-delis-alliance.service';
import {AuthService} from '../services/auth.service';
import {NgbCalendar, NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faTimes, faPlus, faSearch, faFilter, faCaretDown, faCaretUp, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {PreloaderService} from '../preloader.service';
import * as moment from 'moment';
import {FilterSearch} from '../models/filter_search';
import {MatDialog} from "@angular/material";
import {FiltersAdvComponent} from "../modals/filters-adv/filters-adv.component";

// TODO: remove date type
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  objectKeys = Object.keys;
  faTimes = faTimes;
  faPlus = faPlus;
  faSearch = faSearch;
  faFilter = faFilter;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faInfoCircle = faInfoCircle;

  @ViewChild('modalFilters', {static: false}) private modalFilters: TemplateRef<any>;
  @ViewChild('modalSelectFilterByFile', {static: false}) private modalSelectFilterByFile: TemplateRef<any>;

  public currSearchType: string;
  public currFirm;
  public isNavSidebarOpened: boolean;
  public selectedDocTypes = [];
  public isSelectAllDocTypes = false;
  public docTypes;
  public selectedTable;
  public isStartSearch: boolean = false;

  public filterFile: File;
  public filterFileField: string;
  public activeFilters: any;
  public searchFilter = '';
  public isLoadData = false;
  public isLoadFilters = false;

  daterangeLocale = {
    format: 'DD.MM.YYYY',
    direction: 'ltr',
    weekLabel: 'W',
    separator: ' - ',
    cancelLabel: 'Cancel',
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1,
  };

  daterangeRanges: any = {
    'Сегодня': [moment(), moment().add(1, 'days')],
    'Вчера': [moment().subtract(1, 'days'), moment()],
    'Последние 7 дней': [moment().subtract(6, 'days'), moment().add(1, 'days')],
    'Последние 30 дней': [moment().subtract(29, 'days'), moment().add(1, 'days')],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month').add(1, 'days')],
    'Предыдущий месяц': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month').add(1, 'days')
    ]
  };

  public inputFilters = {};
  public filterStatus = '';
  public additionalFilters: Array<FilterSearch>;
  public additionalFilterGroups = [
    {
      id: '0',
      title: 'Основные',
      isCollapsed: false,
    },
  ];

  public baseFilters = [
    {
      name: 'docTypeSelected',
      group: 'base',
      docType: '0',
      code: 'docTypeSelected',
      title: 'Тип документа',
      type: 'select',
      pattern: '=',
      value: [],
      visible: true,
      baseFilter: true,
    },
    {
      name: 'description',
      group: 'base',
      docType: '0',
      code: 'Header_text_doc',
      title: 'Описание документа',
      type: 'строка',
      pattern: 'contains',
      value: '',
      visible: true,
      baseFilter: true,
    },
    {
      name: 'docNumber',
      group: 'base',
      docType: '0',
      code: 'Number_doc',
      title: 'Номер документа',
      type: 'number',
      pattern: 'contains',
      value: '',
      visible: true,
      baseFilter: true,
    },
    {
      name: 'date',
      group: 'base',
      docType: '0',
      code: 'CT_DOC_IMAGES_CLIENT.Date_doc',
      title: 'Дата',
      type: 'дата',
      pattern: 'contains',
      value: '',
      visible: true,
      baseFilter: true,
    },

    {
      name: 'fulltextSearch',
      group: 'base',
      docType: '0',
      code: '',
      title: 'Поиск по тексту документа',
      type: 'строка',
      pattern: 'contains',
      value: '',
      visible: true,
      baseFilter: true,
    },

    {
      name: 'isHaveMarkForRemoval',
      group: 'base',
      docType: '0',
      code: '',
      title: 'Помечен на удаление',
      type: 'boolean',
      pattern: '=',
      value: '-1',
      visible: true,
      baseFilter: true,
    },

  ];

  constructor(
      private searchDelisAllianceService: SearchDelisAllianceService,
      private authService: AuthService,
      private modalService: NgbModal,
      private preloaderService: PreloaderService,
      public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.currSearchType = 'simple';
    this.isNavSidebarOpened = false;
  }

  onChangedSearchType(type: string): void {
    this.currSearchType = type;
  }

  onToggleSidebar(status: boolean): void {
    this.isNavSidebarOpened = status;
  }

  onChangedFirm(firm: any): void {
    this.isLoadFilters = false;
    this.currFirm = firm;
    FilterSearch.currFirmId = firm['ID_FIRM'];
    this.activeFilters = localStorage.getItem('da_active-filters_' + firm['ID_FIRM']);

    if (!this.activeFilters) {
      this.activeFilters = {};
    } else {
      this.activeFilters = JSON.parse(this.activeFilters);
    }

    // ======================================
    const selectedDocTypesStr = localStorage.getItem('da_selected-docTypes_' + firm['ID_FIRM']);
    if (!selectedDocTypesStr) {
      this.selectedDocTypes = [];
      this.baseFilters[0].value = [];
    } else {
      this.selectedDocTypes = JSON.parse(selectedDocTypesStr);
      this.baseFilters[0].value = this.selectedDocTypes;
    }
    // ======================================

    let that = this;
    this.searchDelisAllianceService
        .getDocTypes(this.authService.user['DA_USER']['DA_CLIENT_ID'], this.authService.user['DA_USER']['DA_LOGIN_ID'])
        .subscribe((data) => {
          this.docTypes = data;
          this.searchDelisAllianceService.getAdditionFilters(this.authService.user['DA_USER']['DA_CLIENT_ID']).subscribe((filters) => {
            that.additionalFilters = [];
            for (const filter of that.baseFilters) {
              that.additionalFilters.push(new FilterSearch(filter));
            }

            filters = filters.map((v) => {
              for (const docType of that.docTypes) {
                if (docType.id_type === v.ID_DOC_TYPE) {
                  let isHave = false;
                  for (const filterGroup of that.additionalFilterGroups) {
                    if (filterGroup.id === v.ID_DOC_TYPE) {
                      isHave = true;
                      break;
                    }
                  }
                  if (!isHave) {
                    that.additionalFilterGroups.push({
                      id: v.ID_DOC_TYPE,
                      title: docType.Description_type,
                      isCollapsed: true,
                    });
                  }
                  break;
                }
              }

              return new FilterSearch({
                name: v.ATTRIBUTE_NAME,
                title: v.ATTRIBUTE_NAME,
                type: v.TYPE_RUS_NAME,
                code: v.ATTRIBUTE_NAME,
                docType: v.ID_DOC_TYPE,
                group: 'addition',
                pattern: 'contains',
                value: null,
                visible: true,
                baseFilter: false,
              });
            });
            that.additionalFilters = that.additionalFilters.concat(filters);
            that.isLoadFilters = true;
          });
        });
  }

  search(): void {
    this.isStartSearch = false;
    setTimeout(() => {
      this.isStartSearch = true;
    }, 100);
  }

  openFiltersModal(): void {
    const dialogRef = this.dialog.open(FiltersAdvComponent, {
      width: '95%',
      data: {
        docTypes: this.docTypes,
        selectedDocTypes: this.selectedDocTypes,
        additionalFilters: this.additionalFilters,
        searchFilter: this.searchFilter,
        onChangeSelectedDocTypes: this.onChangeSelectedDocTypes,
        onChangeSearchFilter: this.onChangeSearchFilter,
        clearFilter: this.clearFilter,
        setFilterFile: this.setFilterFile,
        filterIsDisable: this.filterIsDisable,
        onDaterangeUpdate: this.onDaterangeUpdate,
        daterangeLocale: this.daterangeLocale,
        daterangeRanges: this.daterangeRanges,
        additionalFilterGroups: this.additionalFilterGroups,
        isStarSearch: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.search();
      }
    });
  }

  changeFilterInSearch(field): void {
    let index = 0;
    for (let i = 0; i < this.additionalFilters.length; i++) {
      if (this.additionalFilters[i]['name'] === field) {
        index = i;
        break;
      }
    }

    this.additionalFilters[index]['inSearch'] = !this.additionalFilters[index]['inSearch'];
    this.activeFilters[field] = this.additionalFilters[index]['inSearch'];
    localStorage.setItem('da_active-filters_' + this.currFirm['ID_FIRM'], JSON.stringify(this.activeFilters));
  }

  resetFilters(): void {
    this.selectedDocTypes = [];
    for (let i = 0; i < this.additionalFilters.length; i++) {
      this.additionalFilters[i].value = null;
    }
  }

  updateInputFilters(): void {
    this.inputFilters = {};
    this.filterStatus = '';

    let values = [];
    for (const selectedDocType of this.selectedDocTypes) {
      for (const docType of this.docTypes) {
        if (docType.id_type === selectedDocType) {
          values.push(docType.Description_type);
          break;
        }
      }
    }

    if (values.length > 0) {
      this.filterStatus += 'Тип документа=' + values.join(', ') + '; ';
      this.inputFilters['docTypeSelected'] = this.selectedDocTypes;
    }

    for (let filter of this.additionalFilters) {
      if (filter.code === 'docTypeSelected') {
        continue;
      }

      if(['date', 'дата'].indexOf(filter.type) !== -1 && filter.value.endDate !== null) {
        this.filterStatus += filter.title
            + '=' + filter.value.startDate.format('DD.MM.YYYY')
            + ' - ' + filter.value.endDate.format('DD.MM.YYYY') + '; ';
      } else if(filter.value) {
        this.filterStatus += filter.title + '=' + filter.value + '; ';
      }
    }
  }

  onChangeSelectedDocType($event, filter): void {
    if (!$event.isUserInput) {
      return ;
    }

    if ($event.source.value === '1000' && !$event.source.active) {
      this.isSelectAllDocTypes = true;
    } else if ($event.source.value === '1000' && !$event.source.active) {
      this.isSelectAllDocTypes = false;
    } else if ($event.source.value !== '1000') {
      this.isSelectAllDocTypes = false;
    }

  }

  onChangeSelectedDocTypes($event, filter: FilterSearch, recurse = true): void {
    if (this.isSelectAllDocTypes) {
      this.additionalFilters[0].value = ['1000'];
    } else {
      const allIndex = this.additionalFilters[0].value.indexOf('1000');

      if (allIndex !== -1) {
        this.additionalFilters[0].value.splice(allIndex, 1);
      }
    }

    // TODO:
    const values = this.additionalFilters[0].value.slice();
    this.additionalFilters[0].value = values;

    this.selectedDocTypes = this.additionalFilters[0].value;
    localStorage.setItem('da_selected-docTypes_' + this.currFirm['ID_FIRM'], JSON.stringify(this.selectedDocTypes));
    filter.value = this.selectedDocTypes;

    if (this.selectedDocTypes.length === 0 || this.selectedDocTypes.indexOf('1000') !== -1) {
      return;
    }

    for (let filter of this.additionalFilters) {
      if (filter.docType === 0) {
        continue;
      }

      if (this.filterIsDisable(filter)) {
        filter.value = '';
      }
    }
  }

  afterSearch(): void {
    this.updateInputFilters();
  }

  onChangeSearchFilter(): void {
    for (let filter of this.additionalFilters) {
      if (this.searchFilter !== '' && !filter.title.toLowerCase().match(this.searchFilter.toLowerCase())) {
        filter.visible = false;
      } else {
        filter.visible = true;
      }
    }
  }

  clearFilter($event, filter): void {
    if (filter.type === 'select') {
      this.selectedDocTypes = [];
      this.onChangeSelectedDocTypes([], filter);
    } else if (['date', 'дата'].indexOf(filter.type) !== -1) {
      filter.value = null;
    } else if(filter.type === 'boolean') {
      filter.value = '-1';
    } else {
      filter.value = null;
    }
  }


  setFilterFile($event): void {
    let files = $event.srcElement.files;
    if (!files) {
      return;
    }

    this.filterFile = files[0];
    this.modalService.open(this.modalSelectFilterByFile, {size: 'lg'});
  }

  filterIsDisable(filter): boolean {
    return this.selectedDocTypes.indexOf(filter.docType) === -1
        && filter.docType !== '0'
        && this.selectedDocTypes.length !== 0
        && this.selectedDocTypes.indexOf('1000') === -1;
  }

  onDaterangeUpdate(): void {
    $('.md-drppicker .buttons_input button').trigger('click');
  }

  onPreloaderCancel($event): void {
      setTimeout(() => {
          this.preloaderService.hide();
          this.isStartSearch = false;
      }, 100);
  }

  onChangeDate($event, filter): void {
    if($event.target && $event.target.value) {
      let dateArr = $event.target.value.toString().split(' - ');
      filter.value.startDate = moment(dateArr[0], 'DD.MM.YYYY');
      filter.value.endDate = moment(dateArr[1], 'DD.MM.YYYY');
    }
  }
}
