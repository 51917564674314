import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CookieService} from 'angular2-cookie/core';
import {environment} from '../../environments/environment';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class AuthService {

    private apiUrl = environment.apiUrl + '/api/auth';
    public user: any;

    constructor(
        private httpClient: HttpClient,
        private cookieService: CookieService,
        private apollo: Apollo
    ) {
    }

    isAuth(): boolean {
        if (this.user) {
            return true;
        } else {
            return false;
        }
    }

    getUser(): Observable<any> {
        // return this.httpClient.get<IUser>(this.apiUrl + '/get-user');
        return this.apollo.watchQuery({
            query: gql`
                  {
                      curr_user {
                        ID_LOGIN
                        LOGIN_VALUE
                        NAME_USER,
                        SEARCH_TABLES {
                          TABLE_NAME_WITHOUT_DB,
                          SHORT_NAME
                        },
                        DA_USER {
                            DA_LOGIN_ID,
                            DA_CLIENT_ID
                        }
                        
                      }
                   }
            `,
        }).valueChanges;
    }

    login(username, pass): any {
        const data = {
            grant_type: 'password',
            username: username,
            password: pass,
            client_id: 'web_cloud',
            client_secret: 'gtAZN7lmOs0muZVulBOXwd72P4WsaacP'
        };
        return this.httpClient.post(environment.apiUrl + '/api/oauth2/token', data);
    }

    options(): any {
        return this.httpClient.options(environment.apiUrl + '/api/oauth2/options');
    }

    logout(): Observable<boolean> {
        return this.httpClient.post<boolean>(this.apiUrl + '/api/logout', []);
    }
}
