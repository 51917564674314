import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './search/search.component';
import { NavComponent } from './search/nav/nav.component';
import { SimpleComponent } from './search/simple/simple.component';
import { AttrsComponent } from './search/adv/attrs/attrs.component';
import { AllFieldsComponent } from './search/adv/all-fields/all-fields.component';
import { AllTypesComponent } from './search/adv/all-types/all-types.component';
import { GroupsComponent } from './search/adv/groups/groups.component';
import { FullTextComponent } from './search/adv/full-text/full-text.component';
import { AdvComponent } from './search/adv/adv.component';
import { DataGridComponent } from './search/data-grid/data-grid.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {AuthGuard} from './services/auth-guard';
import {AuthService} from './services/auth.service';
import { LoginComponent } from './auth/login/login.component';
import {FormsModule} from '@angular/forms';
import {CookieService} from 'angular2-cookie/core';
import { GraphQLModule } from './graphql.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchDelisAllianceService} from './services/search-delis-alliance.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { NavSidebarComponent } from './search/nav-sidebar/nav-sidebar.component';
import { AllianceDatatableComponent } from './search/simple/alliance-datatable/alliance-datatable.component';
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar';
import { SortableDirective } from './search/data-grid/sortable.directive';
import {DataTablesModule} from 'angular-datatables';
import {DocumentsDelisAllianceService} from './services/documents-delis-alliance.service';
import {PreloaderService} from './preloader.service';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import {MdbSortableModule} from 'mdb-sortable';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import { FiltersAdvComponent } from './modals/filters-adv/filters-adv.component';
import { EditDocsComponent } from './modals/edit-docs/edit-docs.component';
import {MatRadioModule, MatSlideToggleModule} from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    NavComponent,
    SimpleComponent,
    AttrsComponent,
    AllFieldsComponent,
    AllTypesComponent,
    GroupsComponent,
    FullTextComponent,
    AdvComponent,
    DataGridComponent,
    LoginComponent,
    NavSidebarComponent,
    AllianceDatatableComponent,
    SortableDirective,
    FiltersAdvComponent,
    EditDocsComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        NgSelectModule,
        FormsModule,
        HttpClientModule,
        GraphQLModule,
        FontAwesomeModule,
        MalihuScrollbarModule.forRoot(),
        DataTablesModule,
        MDBBootstrapModule,
        MdbSortableModule,
        NgxDaterangepickerMd.forRoot(),
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatRadioModule,
    ],
  providers: [
    CookieService,
    AuthService,
    SearchDelisAllianceService,
    DocumentsDelisAllianceService,
    PreloaderService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
      FiltersAdvComponent,
      EditDocsComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
