import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-text-search',
  templateUrl: './full-text.component.html',
  styleUrls: ['./full-text.component.sass']
})
export class FullTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
