export class FilterSearch {
    private static activeFilters = {};
    private static activeFiltersStoragePrefix = 'da_active-filters_';
    public static currFirmId: string;

    name: string;
    group: string;
    docType: string|number;
    code: string;
    title: string;
    type: string;
    pattern: string;
    value?: any;
    visible: boolean;
    baseFilter: boolean;

    private _inSearch: boolean;

    public constructor(init?: Partial<FilterSearch>) {
        Object.assign(this, init);
    }

    get inSearch(): boolean {
        if (this.baseFilter) {
            return true;
        }

        if (!(FilterSearch.currFirmId in Object.keys(FilterSearch.activeFilters))) {
            const keyStorage = FilterSearch.activeFiltersStoragePrefix + FilterSearch.currFirmId;
            const activeFiltersJson = localStorage.getItem(keyStorage);
            if (!activeFiltersJson) {
                FilterSearch.activeFilters[FilterSearch.currFirmId] = {};
            } else {
                FilterSearch.activeFilters[FilterSearch.currFirmId] = JSON.parse(activeFiltersJson);
            }
        }

        const activeFilters = FilterSearch.activeFilters[FilterSearch.currFirmId];
        if (Object.keys(activeFilters).indexOf(this.code) !== -1) {
            return activeFilters[this.code];
        } else {
            return false;
        }
    }

    set inSearch(value: boolean) {
        FilterSearch.activeFilters[FilterSearch.currFirmId][this.code] = value;
        const activeFiltersJson = JSON.stringify(FilterSearch.activeFilters[FilterSearch.currFirmId]);
        const keyStorage = FilterSearch.activeFiltersStoragePrefix + FilterSearch.currFirmId;
        localStorage.setItem(keyStorage, activeFiltersJson);
    }
    
    isHasValue(): boolean {
        if (this.code === 'docTypeSelected') {
            return this.value && this.value.length > 0;
        } else if (this.type === 'дата') {
            return this.value && this.value.endDate;
        }else if(this.type === 'boolean') {
            return this.value != '-1';
        } else {
            return this.value;
        }
    }
}
