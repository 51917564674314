import {Component, Inject, OnInit} from '@angular/core';
import {DocumentsDelisAllianceService} from '../../services/documents-delis-alliance.service';
import {PreloaderService} from "../../preloader.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-edit-docs',
  templateUrl: './edit-docs.component.html',
  styleUrls: ['./edit-docs.component.scss']
})
export class EditDocsComponent implements OnInit {

  public EDIT_MODE_SINGLE = 'single';
  public EDIT_MODE_MULTI = 'multi';

  public isLoadAttributes = false;
  public documentEditAttributes = [];
  public selected;
  public selectedId;
  public docCard;

  public editMode = this.EDIT_MODE_SINGLE;


  constructor(
      public dialogRef: MatDialogRef<EditDocsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private documentsDelisAllianceService: DocumentsDelisAllianceService,
      public preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    this.preloaderService.show();
    this.selected = this.data.selected;
    this.selectedId = this.data.selectedId;
    this.docCard = this.data.docCard;

    this.documentsDelisAllianceService.getEditColumns().subscribe((response) => {
      for (let attribute of response) {
        attribute.value = '';
        this.documentEditAttributes.push(attribute);
      }
      this.documentEditAttributes = response;
      this.preloaderService.hide();
      this.onChangeEditMode();
      this.isLoadAttributes = true;
    }, (response) => {
      alert(response);
      this.preloaderService.hide();
    });
  }

  close(): void {
    this.dialogRef.close(true);
  }

  onUpdateDocumentsAttributes($event): void {
    if (this.editMode === this.EDIT_MODE_MULTI) {

      const resultConfirm = confirm('Текущие изменения будут применены для ' + this.selected.length.toString() + ' документов. Вы подтверждаете выполнение данной операции?');
      if (!resultConfirm) {
        return ;
      }
    }
    const ids = this.editMode === this.EDIT_MODE_SINGLE ? [this.selected[0]] : this.selected;

    let attributes = [];
    for (let attribute of this.documentEditAttributes) {
      if (this.editMode === this.EDIT_MODE_SINGLE && !(attribute.value === this.docCard[attribute.ATTRIBUTE_NAME])) {
        attributes.push(attribute);
      }
      if (this.editMode === this.EDIT_MODE_MULTI && !(attribute.value === '')) {
        attributes.push(attribute);
      }
    }

    this.preloaderService.show();
    this.documentsDelisAllianceService.updateDocumentsAttributes(ids, attributes).subscribe((response) => {
      this.preloaderService.hide();
      alert('Сохранено');
      this.dialogRef.close(true);
    }, (error) => {
      alert('Ошибка при обновлении образа');
      this.preloaderService.hide();
      this.dialogRef.close(false);
    });
  }


  public onChangeEditMode(): void {
    for (const attribute of this.documentEditAttributes) {
      attribute.value = this.editMode === this.EDIT_MODE_SINGLE ? this.docCard[attribute.ATTRIBUTE_NAME] : '';
    }
  }

  public checkFieldIsChangedValue(attribute): boolean {
    if (this.editMode === this.EDIT_MODE_SINGLE) {
      return !(attribute.value === this.docCard[attribute.ATTRIBUTE_NAME]);
    } else {
      return !(attribute.value === '');
    }
  }

  public isChangedForm(): boolean {
    for (const attribute of this.documentEditAttributes) {
      if (this.editMode === this.EDIT_MODE_SINGLE && !(attribute.value === this.docCard[attribute.ATTRIBUTE_NAME])) {
        return true;
      }
      if (this.editMode === this.EDIT_MODE_MULTI && !(attribute.value === '')) {
        return true;
      }
    }
    return false;
  }

}
