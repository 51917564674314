import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {environment} from '../../environments/environment';
import * as object_hash from 'object-hash/dist/object_hash.js';
import {moveItemInList} from "mdb-sortable";
import {DataGridComponent} from "../search/data-grid/data-grid.component";

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}


@Injectable()
export class SearchDelisAllianceService
{
    private apiUrl = environment.apiUrl + '/api/search-delis-alliance';

    constructor(
        private httpClient: HttpClient
    ) {}


    options(): any {
        return this.httpClient.options(environment.apiUrl + '/api/auth-delis-alliance/login');
    }

    login(idFio, evhash='www', clientId): Observable<any> {
        let data = {
            clientId: clientId,
            idFio: idFio,
            evhash: evhash,
        };
        return this.httpClient.post<any>(environment.apiUrl + '/api/auth-delis-alliance/login', data);
    }

    getClients(searchClients): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/get-clients?search=' + searchClients);
    }

    getDocTypes(clientId, userId): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + `/get-doc-types?clientId=${clientId}&userId=${userId}`);
    }

    getColumns(formData: FormData): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + '/datatable-get-columns', formData);
    }

    search(clientId, docTypesSelected, filters): Observable<any> {
        const data = {
            clientId: clientId,
            docTypesSelected: docTypesSelected,
            filters: filters,
        };

        return this.httpClient.post<any>(this.apiUrl + '/search', data);
    }

    getUsers(clientId): Observable<any> {
        return this.httpClient.get(this.apiUrl + '/get-users?clientId=' + clientId);
    }

    getFirms(userId): Observable<any> {
        return this.httpClient.get(this.apiUrl + '/get-firms?userId=' + userId);
    }

    getAdditionFilters(clientId): Observable<any> {
        return this.httpClient.get(this.apiUrl + '/get-additional-filters?clientId=' + clientId);
    }

    loadImages(imageIds): Observable<any> {
        const data = {
            imageIds: imageIds,
        };
        return this.httpClient.post(this.apiUrl + '/load-images', data);
    }

    getEvhash(): Observable<any> {
        return this.httpClient.get(this.apiUrl + '/get-evhash');
    }

    //TODO: DRY
    getSearchResult(context: DataGridComponent, params): Observable<any> {
        const formData: FormData = new FormData();
        if (context.filterFile && context.filterFileField) {
            formData.append('filterFile', context.filterFile);
        }

        const data = {
            hashSearch: '',
            firm: context.firm,
            dtParams: params,
            docTypeSelected: context.selectedTable,
            filters: context.additionalFilters,
            optionsColumns: context.optionsColumns,
        };
        formData.append('data', JSON.stringify(data));

        return this.httpClient.post(this.apiUrl + '/get-search-result', formData);
    }

    searchDatatable(context: DataGridComponent, params, callback): void {
        context.docCards = [];
        context.preloaderService.show();
        context.showDatatable = false;

        const formData: FormData = new FormData();
        if (context.filterFile && context.filterFileField) {
            formData.append('filterFile', context.filterFile);
        }

        const data = {
            hashSearch: '',
            firm: context.firm,
            dtParams: params,
            docTypeSelected: context.selectedTable,
            filters: context.additionalFilters,
            optionsColumns: context.optionsColumns,
        };
        formData.append('data', JSON.stringify(data));

        this.httpClient
            .post<DataTablesResponse>(
                environment.apiUrl + '/api/search-delis-alliance/datatable-search',
                formData, {}
            )
            .subscribe(resp => {
                if (resp.data.length === 0) {
                    context.isShow = false;
                    alert('Не найдено ни одного образа');
                    context.preloaderService.hide();
                    return;
                }
                context.isShow = true;
                context.showColumns = true;
                $('.dataTable.hide-columns').removeClass('hide-columns');

                context.docCards = resp.data;

                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data
                });

                // visible, sorted columns
                context.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    for (let i = 0; i < context.columns.length; i++) {
                        dtInstance.columns([i]).visible(context.columns[i]['visible']);
                    }

                    dtInstance.on( 'column-reorder', ( e, settings, details ) => {
                        moveItemInList(context.columns, details.from, details.to);
                        context.updateColumnsSortStorage();
                    } );

                    dtInstance.on('order', () => {
                        const order = dtInstance.order();
                    });
                });
                context.showDatatable = true;
                context.preloaderService.hide();
                context.afterSearch.emit();

            });
    }
}
