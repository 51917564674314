import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alliance-datatable',
  templateUrl: './alliance-datatable.component.html',
  styleUrls: ['./alliance-datatable.component.scss']
})
export class AllianceDatatableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
