import {
    Component,
    ElementRef,
    EventEmitter, HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {faArrowLeft, faCheck, faInfoCircle, faEye, faTimes, faFileUpload} from '@fortawesome/free-solid-svg-icons';
import {SearchDelisAllianceService} from '../../services/search-delis-alliance.service';
import {AuthService} from '../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as object_hash from 'object-hash/dist/object_hash.js';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import {DocumentsDelisAllianceService} from '../../services/documents-delis-alliance.service';
import {PreloaderService} from '../../preloader.service';
import {moveItemInList} from 'mdb-sortable';
import {FiltersAdvComponent} from "../../modals/filters-adv/filters-adv.component";
import {MatDialog} from "@angular/material";
import {EditDocsComponent} from "../../modals/edit-docs/edit-docs.component";


class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'app-search-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
    objectKeys = Object.keys;
    JSON = JSON;

    faCheck = faCheck;
    faInfoCircle = faInfoCircle;
    faEye = faEye;
    faTimes = faTimes;
    faFileUpload = faFileUpload;
    public scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };

    isShowInfo = false;
    isShow = false;
    showSortColumns = true;
    showInfoData: any;
    showDatatable = true;

    @Input() firm: any;
    @Input() selectedTable: any;
    @Input() data: any;
    @Input() additionalFilters: Array<any>;
    @Input() filterFile: File;
    @Input() filterFileField: string;

    @Output() afterSearch = new EventEmitter<any>();

    selectAll = false;
    selected = [];
    selectedId = 0;
    dtOptions: any = {};
    docCards = [];
    columns = [];
    showColumns = true;
    tablePerPage = 25;
    tableCurrPage = 1;
    baseColumns = [
        '', 'ID_IMAGE', 'BARCODE', 'BU OPE KZ BLR', 'Date_doc', 'Description_type', 'Header_text_doc', 'ID_UNIT', 'IMG_FILE', 'IMG_FORMAT',
        'InnerSize', 'Number_doc', 'SAP corp. year', 'SAP Код контрагента', 'Type_doc'
    ];

    @ViewChild(DataTableDirective, {static: false}) public datatableElement: DataTableDirective;

    @ViewChild('dataGrid', {static: false}) private dataGridChild: ElementRef;

    documentEditAttributes: Array<any>;
    @ViewChild('modalEditDocs', {static: false}) private modalEditDocs: TemplateRef<any>;

    documentUpdateFiles: FileList;
    @ViewChild('modalUpdateDocumentFile', {static: false}) private modalUpdateDocumentFile: TemplateRef<any>;

    optionsColumns: any;
    @ViewChild('modalHiddenColumns', {static: false}) private modalHiddenColumns: TemplateRef<any>;

    isFixedShowInfoBlock = false;
    @ViewChild('showInfoBlock', {static: false}) private showInfoBlock: ElementRef;

    linkBlob = '';
    @ViewChild('modalShowImage', {static: false}) private modalShowImage: TemplateRef<any>;

    linkDa = '';
    @ViewChild('modalGoToDa', {static: false}) private modalGoToDa: TemplateRef<any>;
    imageBlob: string;

    linkDaEdit = '';
    linkDaLogin = '';
    @ViewChild('modalGoToDaEdit', {static: false}) private modalGoToDaEdit: TemplateRef<any>;

    languageData = {
        decimal:        '',
        emptyTable:     'Не найдено записей',
        info:           'Показано от _START_ до _END_ из _TOTAL_ записей',
        infoEmpty:      'Показано от 0 до 0 из 0 записей',
        infoFiltered:   '(filtered from _MAX_ total entries)',
        infoPostFix:    '',
        thousands:      ',',
        lengthMenu:     'Показать _MENU_ записей',
        loadingRecords: 'Загрузка...',
        processing:     'Процесс...',
        search:         'Искать:',
        zeroRecords:    'Нет подходящих записей',
        paginate: {
            first:      'Первая',
            last:       'Последняя',
            next:       'Вперед',
            previous:   'Назад'
        },
        aria: {
            sortAscending:  ': activate to sort column ascending',
            sortDescending: ': activate to sort column descending'
        },

        columnDefs: [ {
            orderable: false,
            className: 'select-checkbox',
            targets:   0
        } ],
        select: {
            style:    'os',
            selector: 'td:first-child'
        },
        order: [[ 1, 'asc' ]]

    };

    constructor(
        private searchDelisAllianceService: SearchDelisAllianceService,
        private documentsDelisAllianceService: DocumentsDelisAllianceService,
        private authService: AuthService,
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        public preloaderService: PreloaderService,
        public dialog: MatDialog
    ) {

    }

    ngOnInit() {
        window.addEventListener('scroll', this.scrollEvent, true);

        // ========================================================================================
        const optionsColumns = localStorage.getItem('da_options-columns_' + this.firm.ID_FIRM);
        if (optionsColumns) {
            try {
                this.optionsColumns = JSON.parse(optionsColumns);
            } catch (e) {
                this.optionsColumns = [];
            }
        } else {
            this.optionsColumns = [];
        }

        if (!this.optionsColumns) {
            this.optionsColumns = [];
        }

        // ========================================================================================

        this.searchDelisAllianceService.getEvhash().subscribe((evhash) => {
            this.linkDaLogin = `${environment.daUrl}/Logon.aspx?login=${this.authService.user.LOGIN_VALUE}&evhash=${evhash}`;
        }, (response) => {
            alert(response);
        });

        const that = this;
        const formData: FormData = new FormData();

        if (this.filterFile && this.filterFileField) {
            formData.append('filterFile', this.filterFile);
        }

        const data = {
            hashSearch: '',
            filterFileField: this.filterFileField,
            firm: that.firm,
            dtParams: {},
            docTypeSelected: that.selectedTable,
            filters: that.additionalFilters,
            optionsColumns: that.optionsColumns,
        };
        formData.append('data', JSON.stringify(data));

        let tableColumns = [{
            title: '',
            data: 'select',
            width: 100,
            defaultContent: '',
            orderable: false
        }];
        for (let column of this.baseColumns) {
            tableColumns.push({
                title: column,
                data: column,
                width: 100,
                defaultContent: '',
                orderable: false,
            });
        }
        that.preloaderService.show();
        this.searchDelisAllianceService.getColumns(formData).subscribe((response: Array<any>) => {
            that.showColumns = false;
            that.columns = [{
                title: '#',
                data: 'select',
                width: 30,
                defaultContent: '',
                orderable: false,
                visible: true,
                sort: 0
            }];

            response.sort((a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                } else if (a.sort > b.sort) {
                    return 1;
                } else {
                    return 0;
                }
            });

            let sort = 1;
            for (const column of response) {
                that.columns.push({
                    title: that.getColumnTitle(column['name']),
                    type: 'html',
                    data: column['name'],
                    width: column['width'] < 80 ? 80 : column['width'],
                    defaultContent: '',
                    orderable: column['can_order'] === 1,
                    className: column['name'].length > 22 ? 'mini-size' : '',
                    visible: that.isColumnVisible(column['name']),
                    sort: that.getColumnSort(column['name'], sort),
                    canSorted: true,
                });
                sort++;
            }

            if (that.optionsColumns.length === 0) {
                that.optionsColumns = that.columns;
            }

            that.buildDtOptions();
        });

    }

    private buildDtOptions(): void {

        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            dom: '<"toolbar">frtipS',
            colReorder: true,
            scrollX: true,
            scrollY: '60vh',
            pageLength: that.tablePerPage,
            lengthMenu: [ 25, 50, 75, 100, 200],
            serverSide: true,
            processing: true,
            searching: false,
            rowId: 'ID_IMAGE',
            language: that.languageData,
            fixedColumns:   {
                leftColumns: 2
            },

            buttons: [ 'colvis' ],
            headerCallback: (thead, data, start, end, display) => {
                let $thCheckbox = $(thead).find('th:first-child').eq(0).html(
                    `
                    <label class="checkbox" style="margin-left: 13px;">
                        <input type="checkbox" />
                        <span class="primary"></span>
                    </label>`
                );

                $thCheckbox.on('change', (e) => {
                    e.preventDefault();
                    that.selectAll = !that.selectAll;
                    that.selected = [];

                    $('.datatable__row__checkbox').each((n, el) => {
                        if (that.selectAll) {
                            that.selected.push($(el).data('id'));
                            $(el).prop('checked', true);
                        } else {
                            $(el).prop('checked', false);
                        }
                    });
                    return false;
                });
            },
            rowCallback: (row, data) => {
                let $row = $(row);
                var id = $row.attr('id');
                var index = $.inArray(parseInt(id), that.selected);

                let $tdCheckbox = $row.find('td:first-child').html(
                    `
                    <label class="checkbox">
                        <input type="checkbox" class="datatable__row__checkbox" ${index === -1 ? '' : 'checked'}  data-id="${$row.attr('id')}" />
                        <span class="primary"></span>
                    </label>`
                );

                if (index !== -1) {
                    $row.addClass('selected');
                }

                let $checkbox = $tdCheckbox.find('input[type="checkbox"]');
                $checkbox.on('change', (e) => {
                    e.preventDefault();
                    var id = $checkbox.data('id');
                    var index = $.inArray(id, that.selected);

                    if ( index === -1 ) {
                        that.selected.push( id );
                    } else {
                        that.selected.splice( index, 1 );
                    }

                    $checkbox.parents('tr').toggleClass('selected-row');
                    return false;
                });

                $row.find('td:not(:first-child)').on('click', () => {
                    var id = $row.attr('id');
                    that.selectedId = parseInt(id);
                    $('#datatable tr').removeClass('selected');
                    $row.addClass('selected');
                    that.showInfo(data);
                });


            },
            ajax: (dataTablesParameters: any, callback) => {
                that.searchDelisAllianceService.searchDatatable(that, dataTablesParameters, callback);
            },
            columns: that.columns,
            columnDefs: [ {
                "targets": 0,
                "searchable": false
            } ]
        };

        that.isShow = true;
    }

    toggleShowColumn($event): void {
        this.showSortColumns = false;
        this.showSortColumns = true;
        $event.stopPropagation();
        this.modalService.open(this.modalHiddenColumns, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
    }

    showInfo(data: any): void {
        this.isShowInfo = true;
        this.showInfoData = data;
    }

    openImage($event): void {
        $event.stopPropagation();

        const selected = this.getSelected();
        if (!selected) {
            alert('Выберите документы');
            return ;
        }
        this.preloaderService.show();

        this.linkBlob = '';
        this.documentsDelisAllianceService.getBlobImage(selected[0]).subscribe((response) => {
            var byteCharacters = atob(response['IMG_CONTENT']);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            this.linkBlob = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
            this.imageBlob = response;
            window.open(this.linkBlob, "_blank");
            this.preloaderService.hide();
        }, (response) => {
            alert(response);
            this.preloaderService.hide();
        });
    }

    openJoinImages($event): void {
        $event.stopPropagation();

        const selected = this.getSelected();
        if (!selected) {
            alert('Выберите документы');
            return ;
        }

        this.preloaderService.show();

        this.documentsDelisAllianceService.getJoinBlobImages(selected).subscribe((response) => {
            var byteCharacters = atob(response['IMG_CONTENT']);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);

            this.linkBlob = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));

            this.imageBlob = response;
            window.open(this.linkBlob, "_blank");
            this.preloaderService.hide();
        }, (response) => {
            alert(response);
            this.preloaderService.hide();
        });

    }


    download($event): void {
        const selected = this.getSelected();
        if (!selected) {
            alert('Выберите документы');
            return ;
        }
        this.preloaderService.show();

        this.documentsDelisAllianceService.download(selected).subscribe((response) => {
            this.preloaderService.hide();
            window.open(environment.apiUrl + response.path, "_blank");
        }, (response) => {
            alert(response);
            this.preloaderService.hide();
        });
    }

    getSearchResult($event): void {
        const that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.preloaderService.show();
            that.searchDelisAllianceService.getSearchResult(that, dtInstance.ajax.params()).subscribe((response) => {
                window.open(environment.apiUrl + response.path, '_blank', '');
                this.preloaderService.hide();
            }, (error) => {
                this.preloaderService.hide();
                alert(error);
            });
        });
    }

    order(type): void {
        if (this.selected.length === 0) {
            return;
        }

        this.documentsDelisAllianceService.order(this.firm, this.selected, type).subscribe((response) => {
            alert('OK');
        });
    }

    isColumnVisible(data: string): boolean {
        let result = true;
        for (let column of this.optionsColumns) {
            if (column['data'] === data) {
                return column['visible'];
            }
        }
        return result;
    }

    getColumnSort(name: string, sortDefault: number): number {
        let result = sortDefault;
        for (let column of this.optionsColumns) {
            if (column['data'] === name && this.objectKeys(column).indexOf('sort') !== -1) {
                return column['sort'];
            }
        }
    }

    onDropColumnSort(event: any) {
        const that = this;
        const oldIndex = event.oldIndex + 1;

        const updateColumnSort = () => {
            let newOrder = [0];
            let newColumns = [{
                title: '#',
                data: 'select',
                width: 30,
                defaultContent: '',
                orderable: false,
                visible: true,
                sort: 0
            }];
            $('.modal-hidden-columns__item').each((i, el) => {
                newOrder.push(0 + $(el).data('index'));
                let column = $(el).data('column');
                column.sort = i + 1;
                newColumns.push(column);
            });
            setTimeout(() => {
                that.columns = newColumns;
                that.updateColumnsSortStorage();
            }, 300);

            // $('.modal-hidden-columns__item').each((i, el) => {
            //     $(el).data('index', i + 1);
            // });

            that.datatableElement.dtInstance.then((dtInstance: any) => {
                dtInstance.colReorder.order(newOrder);
            });
        };

        setTimeout(updateColumnSort, 100);
    }


    getColumnTitle(name): void {
        const renameColumns = {
            IMG_FILE: 'Документ',
            Date_doc: 'Дата документа',
            Number_doc: 'Номер документа',
            Header_text_doc: 'Описание документа',
            Description_type: 'Тип документа',
            BARCODE: 'ШК объекта хранения',
        };

        if (this.objectKeys(renameColumns).indexOf(name) !== -1) {
            return renameColumns[name];
        } else {
            return name;
        }
    }

    public updateColumnsSortStorage(): void {
        const that = this;
        for (let i = 0; i < this.columns.length; i++) {
            let isHasOptionColumn = false;
            this.columns[i].sort = i;
            for (const optionColumn of this.optionsColumns) {
                if (optionColumn.data === this.columns[i].data ) {
                    optionColumn.sort = i;
                    isHasOptionColumn = true;
                    break;
                }
            }

            if (!isHasOptionColumn) {
                this.optionsColumns.push({
                    data: this.columns[i].data,
                    visible: true,
                    sort: i,
                });
            }
        }

        // TODO: rendering selected row
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.cells('td:first-child').nodes().each((el, i) => {
                let $row = $(el).parents('tr');
                const id = $row.attr('id');
                const index = $.inArray(parseInt(id), that.selected);

                $(el).html(
                    `
                    <label class="checkbox">
                        <input type="checkbox" ${index === -1 ? '' : 'checked'}  data-id="${$row.attr('id')}" />
                        <span class="primary"></span>
                    </label>`
                );
            });
        });

        localStorage.setItem('da_options-columns_' + this.firm.ID_FIRM, JSON.stringify(this.optionsColumns));
    }

    changeVisibleColumn($event, column): void {
        let that = this;
        let cacheIndex = -1;
        for (let i = 0; i < this.optionsColumns.length; i++) {
            if (this.optionsColumns[i].data === column.data) {
                cacheIndex = i;
                break;
            }
        }

        if (cacheIndex === -1) {
            this.optionsColumns.push({
                data: column.data,
                visible: column.visible,
            });
            cacheIndex = this.optionsColumns.length - 1;
        } else {
            this.optionsColumns[cacheIndex] = column.visible;
        }

        localStorage.setItem('da_options-columns_' + this.firm.ID_FIRM, JSON.stringify(this.optionsColumns));

        let index = -1;
        for (let i = 0; i < this.columns.length; i++) {
            if (this.columns[i].data === column.data) {
                index = i;
                break;
            }
        }

        if (index !== -1) {
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.columns([index]).visible(that.columns[index]['visible']);
            });
        }
    }

    searchUnits($event): void {
        $event.stopPropagation();
        if (this.selected.length === 0) {
            return;
        }

        let barcodes = [];
        for (let select of this.selected) {

            let barcode = '';
            for (let docCard of this.docCards) {
                if (parseInt(docCard['ID_IMAGE']) === select) {
                    barcode = docCard['BARCODE'];
                    break;
                }
            }

            if (barcode && barcodes.indexOf(barcode) === -1) {
                barcodes.push(barcode);
            }
        }

        this.searchDelisAllianceService.getEvhash().subscribe((evhash) => {
            this.linkDa = `${environment.daUrl}/Logon.aspx?login=${this.authService.user.LOGIN_VALUE}&evhash=${evhash}&unitFilter=${barcodes.join(' || ')}`;
            this.modalService.open(this.modalGoToDa, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
        }, (response) => {
            alert(response);
        });
    }

    edit($event): void {
        $event.stopPropagation();
        if (this.selected.length === 0) {
            return;
        }

        let docCard = null;
        for (let docCardRow of this.docCards) {
            if (parseInt(docCardRow['ID_IMAGE']) === this.selected[0]) {
                docCard = docCardRow;
                break;
            }
        }

        this.linkDaEdit = `${environment.daUrl}/EditItemElArchive.aspx?id_image=${docCard['ID_IMAGE']}&file=${docCard['IMG_FILE']}&type=3&output=attachment`;
        this.modalService.open(this.modalGoToDaEdit, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
    }

    scrollEvent(event: any): void {
        if ($('.documents-actions')[0].offsetTop <= window.pageYOffset) {
            $('.documents-actions').addClass('fixed-top');
            $('.data-grid').addClass('fixed-top');
        } else {
            $('.documents-actions').removeClass('fixed-top');
            $('.data-grid').removeClass('fixed-top');
        }
    }

    onChangeTableRowsPerPage(): void {
        const that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.page.len(that.tablePerPage).draw('page');
        });
    }

    onChangeTablePage(): void {
        const that = this;
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            const pageInfo = dtInstance.page.info();
            if (that.tableCurrPage < 1) {
                that.tableCurrPage = 1;
            }
            if (that.tableCurrPage > pageInfo.pages) {
                that.tableCurrPage = pageInfo.pages;
            }
            dtInstance.page(that.tableCurrPage - 1).draw('page');
        });
    }

    sanitizeF(url: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getColumnNumber(i, count): number {
        // i++;

        // TODO: поправка на первую не учитываемую колонку
        count--;
        const onColumn = Math.trunc(count / 3);
        const delta = count % 3;

        if (delta === 0 && i <= onColumn) {
            return 1;
        } else if (delta > 0 && i <= onColumn + 1) {
            return 1;
        } else if (delta === 0 && i <= onColumn * 2) {
            return 2;
        } else if (delta === 1 && i <= onColumn * 2 + 1) {
            return 2;
        } else if (delta === 2 && i <= onColumn * 2 + 2) {
            return 2;
        } else {
            return 3;
        }
    }

    onClickEditDocs($event): void {
        if (this.selected.length === 0) {
            alert('Необходимо выбрать хотя бы один документ для редактирования');
            return ;
        }

        const docCardData = this.getCardDataById(this.selected[0]);
        const dialogRef = this.dialog.open(EditDocsComponent, {
            width: '95%',
            data: {
                selected: this.selected,
                selectedId: this.selectedId,
                docCard: docCardData,
            }
        });

        const that = this;
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }
        });
    }

    getCardDataById(docCardId: number): any {

        for (const id in this.docCards) {

            if (parseInt(this.docCards[id]['ID_IMAGE'], null) === docCardId) {
                return this.docCards[id];
            }
        }

        return null;
    }

    onClickUpdateDocumentFile(): void {
        this.modalService.open(this.modalUpdateDocumentFile, {size: 'lg', ariaLabelledBy: 'modal-basic-title'});
    }

    onChangeUpdateDocumentFile($event): void {
        this.documentUpdateFiles = $event.target.files;
    }

    onSubmitUpdateDocumentFile(): void {
        const selected = this.getSelected();

        if (this.selectedId <= 0) {
            alert('Выберите документ(ы)');
        }

        this.preloaderService.show();
        this.documentsDelisAllianceService
            .updateDocumentFile(this.selectedId, this.documentUpdateFiles)
            .subscribe((response) => {
                alert('Образ документа обновлен');
                this.preloaderService.hide();
            }, (error) => {
                alert('Ошибка при обновлении образа');
                this.preloaderService.hide();
            });
    }

    // Команды удаления
    onMarkForRemovalSelected($event): void {
        if (this.selected.length === 0) {
            alert('Необходимо выбрать хотя бы один документ');
            return ;
        }

        if(!confirm(`Вы действительно хотите пометить на удаление ${this.selected.length} документов?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .markForRemovalSelected(this.selected)
            .subscribe((response) => {
                alert('Документы успешно помечены на удаление');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при пометки на удаление');
                this.preloaderService.hide();
            });

    }

    onMarkForRemovalAll($event): void {
        if(!confirm(`Вы действительно хотите пометить на удаление все документы?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .markForRemovalAll()
            .subscribe((response) => {
                alert('Документы успешно помечены на удаление');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при пометки на удаление');
                this.preloaderService.hide();
            });
    }

    onUnmarkForRemovalSelected($event): void {
        if (this.selected.length === 0) {
            alert('Необходимо выбрать хотя бы один документ для снятия метки удаления');
            return ;
        }

        if(!confirm(`Вы действительно хотите снять метку на удаление ${this.selected.length} документов?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .unmarkForRemovalSelected(this.selected)
            .subscribe((response) => {
                alert('У документов успешно снята метка на удаление');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при снятие на удаление');
                this.preloaderService.hide();
            });

    }

    onUnmarkForRemovalAll($event): void {
        if(!confirm(`Вы действительно хотите снять метку на удаление для всех документов?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .unmarkForRemovalAll()
            .subscribe((response) => {
                alert('У документов успешно снята метка на удаление');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при снятие на удаление');
                this.preloaderService.hide();
            });
    }

    onRemoveMarkedSelected($event): void {
        if (this.selected.length === 0) {
            alert('Необходимо выбрать хотя бы один документ для редактирования');
            return ;
        }

        if(!confirm(`Вы действительно хотите удалить ${this.selected.length} документов?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .removeMarkedSelected(this.selected)
            .subscribe((response) => {
                alert('Документы успешно удалены');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при удаление');
                this.preloaderService.hide();
            });
    }

    onRemoveMarkedAll($event): void {
        if(!confirm(`Вы действительно хотите удалить все помеченные документы?`)) {
            return ;
        }

        this.preloaderService.show();
        const that = this;
        this.documentsDelisAllianceService
            .removeMarkedAll()
            .subscribe((response) => {
                alert('Документы успешно удалены');
                this.preloaderService.hide();
                this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.page.len(that.tablePerPage).draw('page');
                });
            }, (error) => {
                alert('Ошибка при удаление');
                this.preloaderService.hide();
            });
    }

    // END Команды удаления


    private getSelected(): Array<any> {
        if (this.selected.length === 0 && this.selectedId === 0) {
            return [];
        } else if (this.selected.length > 0) {
            return this.selected;
        } else if (this.selectedId > 0) {
            return [this.selectedId];
        }
    }
}
