import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { CookieService } from 'angular2-cookie/services/cookies.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(public authService: AuthService,
                public router: Router,
                private cookieService: CookieService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
        return new Observable<boolean>(observer => {
            this.authService.getUser().subscribe((response) => {
                if (response.data.curr_user) {
                    this.authService.user = response.data.curr_user;
                    // this.cookieService.put('token', dataUser.TOKEN);
                    return observer.next(true);
                } else {
                    this.cookieService.put('token', '');
                    this.router.navigate(['login']);
                    return observer.next(false);
                }
            }, (response) => {
                this.cookieService.put('token', '');
                this.router.navigate(['login']);
                return observer.next(false);
            });
        });
    }
}
