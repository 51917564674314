import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-fields-search',
  templateUrl: './all-fields.component.html',
  styleUrls: ['./all-fields.component.sass']
})
export class AllFieldsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
