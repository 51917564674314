import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {DateRange} from '@uiowa/date-range-picker';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class SimpleComponent implements OnInit {
  @Input() docTypes: any;
  public selectedTables: any;

  public isAdvFiltersCollapsed = false;

  public filters = {
    DATE_CREATE: new DateRange(),
    CLIENT_NAME: null,
    ORDER_NUMBER: null,
  };

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.selectedTables = [];
  }

  tablesArrayGroupFn() {
    return "Выбрать все";
  }

}
