import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {faAlignJustify, faFilter, faTimes, faFileAlt, faFileUpload, faEye, faFileImport} from '@fortawesome/free-solid-svg-icons';
import {SearchDelisAllianceService} from '../../services/search-delis-alliance.service';
import {AuthService} from '../../services/auth.service';
import {Md5} from 'ts-md5/dist/md5';
import {environment} from '../../../environments/environment';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  objectKeys = Object.keys;

  @ViewChild('modalListAddedFiles', {static: false}) private modalListAddedFiles: TemplateRef<any>;
  @ViewChild('modalAddFiles', {static: false}) private modalAddFiles: TemplateRef<any>;
  @ViewChild('modalAddDocs', {static: false}) private modalAddDocs: TemplateRef<any>;

  @Output() onChangedSearchType = new EventEmitter<any>();
  @Output() onOpenedSidebar = new EventEmitter<any>();
  @Output() onChangedFirm = new EventEmitter<any>();

  @Input() firms: any;

  faFileAlt = faFileAlt;

  faFileUpload = faFileUpload;
  faEye = faEye;
  faFileImport = faFileImport;


  public selectedFirm: any;

  public daUrl = '';
  public searchType: string;

  public faAlignJustify = faAlignJustify;

  //Добавил это, чтобы кнопка сворачивания показывала элементы при клике
  navbarOpen = false;
  toggleNavbar(){
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(
      public authService: AuthService,
      private modalService: NgbModal,
      private searchDelisAllianceService: SearchDelisAllianceService
  ) {

  }

  ngOnInit() {




    this.searchType = 'simple';
    this.onChangedSearchType.emit(this.searchType);
    this.searchDelisAllianceService.getFirms(this.authService.user['ID_LOGIN']).subscribe((response) => {
      this.firms = response;

      let currFirmId = localStorage.getItem('da_curr_firm_id');

      if (!currFirmId) {
        currFirmId = '0';
      }

      for (let firm of this.firms) {
        if (firm.ID_FIRM === currFirmId) {
          this.selectedFirm = firm;
          this.onChangedFirm.emit(firm);
          break;
        }
      }

    });

    this.searchDelisAllianceService.getEvhash().subscribe((evhash) => {
      this.daUrl = `${environment.daUrl}/Logon.aspx?login=${this.authService.user.LOGIN_VALUE}&evhash=${evhash}`;
    }, (response) => {
      alert(response);
    });
  }

  onChangeSearchType(type: string): void {
    this.searchType = type;
    this.onChangedSearchType.emit(type);
  }

  openSidebar() {
    this.onOpenedSidebar.emit(true);
  }

  onChangeFirm(e, firm) {
    localStorage.setItem('da_curr_firm_id', firm.ID_FIRM);
    this.selectedFirm = firm;
    this.onChangedFirm.emit(firm);
  }

  onClickShowListAddedFiles(): void {
    this.modalService.open(this.modalListAddedFiles, {size: 'lg'});
  }

  onClickAddFiles(): void {
    this.modalService.open(this.modalAddFiles, {size: 'lg'});
  }

  onClickAddDocs(): void {
    this.modalService.open(this.modalAddDocs, {size: 'lg'});
  }

}


