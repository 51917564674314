import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {environment} from '../../environments/environment';
import * as object_hash from 'object-hash/dist/object_hash.js';

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}


@Injectable()
export class DocumentsDelisAllianceService
{
    private apiUrl = environment.apiUrl + '/api/documents-delis-alliance';

    constructor(
        private httpClient: HttpClient
    ) {}

    getBlobImage(docCardId): Observable<any> {
        return this.httpClient.get(this.apiUrl + '/get-blob-image?docCardId=' + docCardId);
    }

    order(firm, docCardIds, type): Observable<any> {
        const data = {
            firm: firm,
            docCardIds: docCardIds,
            type: type,
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/order', data);
    }

    download(docCardIds): Observable<any> {
        const data = {
            docCardIds: docCardIds,
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/download', data);
    }

    getEditColumns(): Observable<any> {
        return this.httpClient.get(environment.apiUrl + '/api/documents-delis-alliance/get-edit-columns');
    }

    updateDocumentsAttributes(selected, documentEditAttributes): Observable<any> {
        const data = {
            docCardIds: selected,
            attributes: documentEditAttributes
        };
        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/update-documents-attributes', data);
    }

    updateDocumentFile(doCardId, files: FileList): Observable<any> {
        const formData = new FormData();
        formData.append('docCardId', doCardId);
        formData.append('file', files[0]);
        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/update-document-file', formData);
    }

    getJoinBlobImages(docIds): Observable<any> {
        const data = {
            docCardIds: docIds
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/get-join-blob-images', data);
    }

    markForRemovalSelected(docIds): Observable<any> {
        const data = {
            docCardIds: docIds
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/mark-for-removal-selected', data);
    }

    markForRemovalAll(): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/mark-for-removal-all', {});
    }

    unmarkForRemovalSelected(docIds): Observable<any> {
        const data = {
            docCardIds: docIds
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/unmark-for-removal-selected', data);
    }

    unmarkForRemovalAll(): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/unmark-for-removal-all', {});
    }

    removeMarkedSelected(docIds): Observable<any> {
        const data = {
            docCardIds: docIds
        };

        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/remove-marked-selected', data);
    }

    removeMarkedAll(): Observable<any> {
        return this.httpClient.post(environment.apiUrl + '/api/documents-delis-alliance/remove-marked-all', {});
    }
}
