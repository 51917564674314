import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { CookieService } from 'angular2-cookie/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchDelisAllianceService} from '../../services/search-delis-alliance.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public clientId: string;
  public idFio: string;
  public pass: string;

  private userLogin: string;
  private evhash: string;

  constructor(private authService: AuthService,
              private router: Router,
              private cookieService: CookieService,
              private activatedRoute: ActivatedRoute,
              private searchDelisAllianceService: SearchDelisAllianceService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.clientId = params['clientId'];
      this.idFio = params['idFio'];
      this.evhash = params['evhash'];
    });
  }

  ngOnInit() {
    if (this.idFio && this.evhash) {
      this.searchDelisAllianceService.login(this.idFio, this.evhash, this.clientId).subscribe((response) => {
        this.cookieService.put('token', response.access_token);
        this.router.navigate(['']);
      });
    }
  }

  onSubmit() {
    this.authService.options().subscribe(() => {
      this.authService.login(this.idFio, this.pass).subscribe((data) => {
        this.cookieService.put('token', data.access_token);
        this.router.navigate(['']);
      }, () => {
        // this.snackBar.open('Ошибка авторизации', 'Закрыть', {
        //   duration: 5000,
        //   horizontalPosition: 'left',
        //   verticalPosition: 'top',
        // });
      });
    });

  }

}
