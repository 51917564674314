import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {faTimes, faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import * as moment from "moment";

@Component({
  selector: 'app-filters-adv',
  templateUrl: './filters-adv.component.html',
  styleUrls: ['./filters-adv.component.scss']
})
export class FiltersAdvComponent implements OnInit {

  public docTypes: any;
  public selectedDocTypes: any;
  public additionalFilters: any;
  public searchFilter;
  public onChangeSelectedDocTypes;
  public onChangeSearchFilter;
  public clearFilter;
  public setFilterFile;
  public filterIsDisable;
  public onDaterangeUpdate;
  public daterangeLocale;
  public daterangeRanges;
  public additionalFilterGroups;

  public isStartSearch = false;

  faTimes = faTimes;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  constructor(public dialogRef: MatDialogRef<FiltersAdvComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.docTypes = data.docTypes;
    this.selectedDocTypes = data.selectedDocTypes;
    this.additionalFilters = data.additionalFilters;
    this.searchFilter = data.searchFilter;
    this.onChangeSelectedDocTypes = data.onChangeSelectedDocTypes;
    this.onChangeSearchFilter = data.onChangeSearchFilter;
    this.clearFilter = data.clearFilter;
    this.setFilterFile = data.setFilterFile;
    this.filterIsDisable = data.filterIsDisable;
    this.onDaterangeUpdate = data.onDaterangeUpdate;
    this.daterangeLocale = data.daterangeLocale;
    this.daterangeRanges = data.daterangeRanges;
    this.additionalFilterGroups = data.additionalFilterGroups;

  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close(this.isStartSearch);
  }

  onChangeDate($event, filter): void {
    if($event.target && $event.target.value) {
      let dateArr = $event.target.value.toString().split(' - ');
      filter.value.startDate = moment(dateArr[0], 'DD.MM.YYYY');
      filter.value.endDate = moment(dateArr[1], 'DD.MM.YYYY');
    }
  }
}
