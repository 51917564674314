import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-types-search',
  templateUrl: './all-types.component.html',
  styleUrls: ['./all-types.component.sass']
})
export class AllTypesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
