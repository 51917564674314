import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit {
  @Input() isOpened: boolean;
  @Input() additionalFilters: any;
  @Output() onClosedSidebar = new EventEmitter<any>();
  @Output() onSelectedFirm = new EventEmitter<any>();

  faArrowLeft = faArrowLeft;
  public scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };

  constructor() { }

  ngOnInit() {
    this.isOpened = false;
  }

  close() {
    this.isOpened = false;
    this.onClosedSidebar.emit(false);
  }

  onChangeFirm(e) {

  }

}
